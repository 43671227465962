import React from 'react'
import { Link } from 'gatsby'

import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/LottieShape'
import AbstractCongratulationsAnimation from 'components/Animations/AbstractCongratulationsAnimation'

import success from 'images/png/success.png'

const Success = () => {
  return (
    <StripeCheckoutRedirectContainer>
      <ShapeContainer>
        <LottieShape
          size={500}
          animationData={AbstractCongratulationsAnimation}
        />
      </ShapeContainer>
      <StripeCheckoutContentContainer>
        <StripeCheckoutRedirectHeader>
          <StripeCheckoutImage src={success} alt="success" />
          Es genial tenerte aquí!
        </StripeCheckoutRedirectHeader>
        <StripeCheckoutRedirectDescription>
          Comienza a explorar mundos 3D e inicia tu viaje de aprendizaje de
          idiomas descargando FluentWorlds.
        </StripeCheckoutRedirectDescription>
        <StripeCheckoutRedirectDescription>
          Si tienes alguna pregunta, envíanos un correo electrónico a{' '}
          <a
            css={`
              color: rgb(63, 129, 238);
            `}
            href="mailto:contacto@FluentWorlds.co"
            aria-label="EmailSupport"
          >
            contacto@FluentWorlds.co
          </a>
          .
        </StripeCheckoutRedirectDescription>
        <Link to="/profile">
          <StripeCheckoutRedirectButton>
            IR A LA CUENTA
          </StripeCheckoutRedirectButton>
        </Link>
      </StripeCheckoutContentContainer>
    </StripeCheckoutRedirectContainer>
  )
}

export default Success
